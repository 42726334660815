import React, { Fragment, useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import scrollToElement from "scroll-to-element"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"
import PricingTooltip from "../components/pricingTooltip"

const data = [
  "Podologia",
  "Fizjoterapia",
  "Chirurgia[br]palca",
  "Kriochirurgia",
  "Angiologia",
  "Usługi[br]pielęgniarskie",
  "Laseroterapia",
  // "Psychologia",
  "Dietetyka",
]

const Pricing = ({ pricing }) => {
  const [pricingTab, setpricingTab] = useState("1")

  return (
    <Layout>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <SEO title="Cennik" />

      <GlobalTop title="Cennik" />

      <section className="pricing-content">
        <div className="container">
          <div className="pricing-content__nav">
            <div className="row">
              {data.map((item, index) => {
                const position = (index + 1).toString()
                const itemSplit = item.split("[br]")

                return (
                  <div className="col-sm-6 col-lg-4 mb-lg-5" key={index}>
                    <div
                      className={`pricing-content__tile pricing-content__tile-${position} ${
                        pricingTab === position && "current"
                      }`}
                      onClick={() => {
                        setpricingTab(position)
                        scrollToElement("#pricing")
                      }}
                    >
                      <h4>
                        {itemSplit.length > 1
                          ? itemSplit.map((item, index) => (
                              <Fragment key={index}>
                                {index !== 0 ? <br /> : ""}
                                {item}
                              </Fragment>
                            ))
                          : item}
                      </h4>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <span className="anchor" id="pricing"></span>
          <div className="pricing-content__list">
            <div className="pricing-content__list-inner">
              <div className="row">
                <div className="col-7">
                  <span className="pricing-content__list-swipe">
                    <span>Przesuń lewo / prawo</span>
                  </span>
                </div>
                <div className="col-3">
                  <img
                    src={require("../assets/images/pricing-ico-price.svg")}
                    alt=""
                  />
                </div>
                <div className="col-2">
                  <img
                    src={require("../assets/images/pricing-ico-duration.svg")}
                    alt=""
                  />
                </div>
              </div>
              {pricingTab === "1"
                ? pricing.nodes[0].acfCennik.podologiaCennik.map(
                    (node, index) => (
                      <div className="row" key={index}>
                        <div className="col-7">{node.zabieg}</div>
                        <div className="col-3">{node.cena}</div>
                        <div className="col-2">{node.czas}</div>
                      </div>
                    )
                  )
                : pricingTab === "2"
                ? pricing.nodes[0].acfCennik.fizjoterapiaCennik.map(
                    (node, index) => (
                      <div className="row" key={index}>
                        <div className="col-7">{node.zabieg}</div>
                        <div className="col-3">{node.cena}</div>
                        <div className="col-2">{node.czas}</div>
                      </div>
                    )
                  )
                : pricingTab === "3"
                ? pricing.nodes[0].acfCennik.chirurgiaPalcaCennik.map(
                    (node, index) => (
                      <div className="row" key={index}>
                        <div className="col-7">{node.zabieg}</div>
                        <div className="col-3">{node.cena}</div>
                        <div className="col-2">{node.czas}</div>
                      </div>
                    )
                  )
                : pricingTab === "4"
                ? pricing.nodes[0].acfCennik.kriochirurgiaCennik.map(
                    (node, index) => (
                      <div className="row" key={index}>
                        <div className="col-7">{node.zabieg}</div>
                        <div className="col-3">{node.cena}</div>
                        <div className="col-2">{node.czas}</div>
                      </div>
                    )
                  )
                : pricingTab === "5"
                ? pricing.nodes[0].acfCennik.angiologiaCennik.map(
                    (node, index) => (
                      <div className="row" key={index}>
                        <div className="col-7">{node.zabieg}</div>
                        <div className="col-3">{node.cena}</div>
                        <div className="col-2">{node.czas}</div>
                      </div>
                    )
                  )
                : pricingTab === "6"
                ? pricing?.nodes[0]?.acfCennik?.uslugiPielegniarskieCennik?.map(
                    (node, index) => (
                      <div className="row" key={index}>
                        <div className="col-7">{node.zabieg}</div>
                        <div className="col-3">{node.cena}</div>
                        <div className="col-2">{node.czas}</div>
                      </div>
                    )
                  )
                : pricingTab === "7"
                ? pricing?.nodes[0]?.acfCennik?.laseroterapiaCennik?.map(
                    (node, index) => (
                      <div className="row" key={index}>
                        <div className="col-7">{node.zabieg}</div>
                        <div className="col-3">
                          {node.cena.wartosc}
                          {node.cena.informacja && (
                            <PricingTooltip content={node.cena.informacja} />
                          )}
                        </div>
                        <div className="col-2">{node.czas}</div>
                      </div>
                    )
                  )
                : pricingTab === "8"
                ? // pricing?.nodes[0]?.acfCennik?.psychologiaCennik?.map(
                  //     (node, index) => (
                  //       <div className="row" key={index}>
                  //         <div className="col-7">{node.zabieg}</div>
                  //         <div className="col-3">
                  //           {node.cena.wartosc}
                  //           {node.cena.informacja && (
                  //             <PricingTooltip content={node.cena.informacja} />
                  //           )}
                  //         </div>
                  //         <div className="col-2">{node.czas}</div>
                  //       </div>
                  //     )
                  //   )

                  pricing?.nodes[0]?.acfCennik?.dietetykaCennik?.map(
                    (node, index) => (
                      <div className="row" key={index}>
                        <div className="col-7">{node.zabieg}</div>
                        <div className="col-3">
                          {node.cena.wartosc}
                          {node.cena.informacja && (
                            <PricingTooltip content={node.cena.informacja} />
                          )}
                        </div>
                        <div className="col-2">{node.czas}</div>
                      </div>
                    )
                  )
                : ""}
            </div>
          </div>

          {pricingTab === "7" &&
            pricing?.nodes[0]?.acfCennik?.laseroterapiaInformacjeDodatkowe && (
              <div className="pricing-content__details">
                <h3>
                  <strong>Informacje dodatkowe:</strong>
                </h3>
                <div
                  className="pricing-content__details-description"
                  dangerouslySetInnerHTML={{
                    __html:
                      pricing.nodes[0].acfCennik
                        .laseroterapiaInformacjeDodatkowe,
                  }}
                />
              </div>
            )}

          {/* {pricingTab === "8" &&
            pricing?.nodes[0]?.acfCennik?.psychologiaInformacjeDodatkowe && (
              <div className="pricing-content__details">
                <h3>
                  <strong>Informacje dodatkowe:</strong>
                </h3>
                <div
                  className="pricing-content__details-description"
                  dangerouslySetInnerHTML={{
                    __html:
                      pricing.nodes[0].acfCennik.psychologiaInformacjeDodatkowe,
                  }}
                />
              </div>
            )} */}
        </div>
      </section>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        pricing: allWpPage(filter: { title: { eq: "Cennik" } }) {
          nodes {
            acfCennik {
              podologiaCennik {
                zabieg
                cena
                czas
              }
              fizjoterapiaCennik {
                zabieg
                cena
                czas
              }
              chirurgiaPalcaCennik {
                zabieg
                cena
                czas
              }
              kriochirurgiaCennik {
                zabieg
                cena
                czas
              }
              angiologiaCennik {
                zabieg
                cena
                czas
              }
              uslugiPielegniarskieCennik {
                zabieg
                cena
                czas
              }
              laseroterapiaCennik {
                zabieg
                cena {
                  wartosc
                  informacja
                }
                czas
              }
              psychologiaInformacjeDodatkowe
              psychologiaCennik {
                zabieg
                cena {
                  wartosc
                  informacja
                }
                czas
              }
              psychologiaInformacjeDodatkowe
              dietetykaCennik {
                zabieg
                cena {
                  wartosc
                  informacja
                }
                czas
              }
            }
          }
        }
      }
    `}
    render={data => <Pricing {...data} />}
  />
)
