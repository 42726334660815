import React, { useState, useRef, useEffect } from "react"

const PricingTooltip = ({ content }) => {
  const [isActive, setIsActive] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = event => {
      let element = event.target
      let isFound = false

      while (element) {
        if (element === dropdownRef.current) {
          isFound = true

          break
        }

        element = element.parentNode
      }

      if (!isFound) {
        setIsActive(false)
      }
    }

    window.addEventListener("click", handleOutsideClick)

    return () => window.removeEventListener("click", handleOutsideClick)
  })

  return (
    <div className="pricing-tooltip" ref={dropdownRef}>
      <button
        className={`pricing-tooltip__toggle${
          isActive ? " pricing-tooltip__toggle--active" : ""
        }`}
        onClick={() => setIsActive(active => !active)}
      >
        <img src={require("../assets/images/info-icon.png")} alt="" />
        Informacje dodatkowe
      </button>
      {isActive && (
        <div
          className="pricing-tooltip__dropdown"
          style={content?.length <= 30 ? { textAlign: "center" } : {}}
        >
          <div className="pricing-tooltip__dropdown-content">
            <button
              className="pricing-tooltip__dropdown-close"
              onClick={() => setIsActive(false)}
            >
              Zamknij
            </button>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      )}
    </div>
  )
}

export default PricingTooltip
